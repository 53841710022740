import { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { mobileModel } from "react-device-detect";
import Header from "./Header";
import "./index.css";
import axios from "axios";
// import priIMG from "../../assets/images/PRI.png";
import priIMG from "../../assets/images/PRI_1.png";
import BtnGP from "../../assets/images/button_base_GP.png";
import BtnAP from "../../assets/images/button_base_AP.png";
import otpload from "../../assets/images/otpload.png";

import { useLocation, useSearchParams } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  body {
    font-family: 'Montserrat'
  }
`;

const link = document.createElement("link");
link.rel = "stylesheet";
link.href =
	"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css";
document.head.appendChild(link);

export default function Button1(props) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [inputValue, setInputValue] = useState("");
	const [number, setNumber] = useState("");
	const [checkNext, setCheckNext] = useState(false);
	const [type, setType] = useState(null);
	const [meta, setMeta] = useState(null);
	const [selectedType, setSelectType] = useState("");
	const location = useLocation();
	const [timeRemaining, setTimeRemaining] = useState(); // Time remaining for the progress bar

	// Calculate percentage for progress bar
	const progressPercentage = (timeRemaining / 30) * 100;

	const handleButtonClick = async () => {
		try {
			try {
				const excelId = searchParams.get("id");
				await axios.post("https://vb-api-lp.vyld.io/forms/registration", {
					phoneNumber: number,
					deviceType: type,
					meta: meta,
					otp: otp,
					websiteData: location,
					excelId,
				});
			} catch (error) {
				if (error?.response?.data?.message == "Invalid OTP") {
					alert("Invalid OTP, Please try again");
					return;
				} else if (error?.response?.data?.message == "already registered") {
					alert("Already registered");
					return;
				} else {
					alert("Something went wrong, Please try again");
					return;
				}
			}
			if (window?.fbq) {
				window?.fbq("track", "CompleteRegistration");
			}
			if (type == "ANDROID")
				window.location.href =
					"https://play.google.com/store/apps/details?id=com.soulgenix.android&pcampaignid=web_share";
			else if (type == "IOS")
				window.location.href =
					"https://apps.apple.com/app/wyb-the-play-social/id6596755967";

			if (type == "WEB") {
				if (selectedType == "android")
					window.location.href =
						"https://play.google.com/store/apps/details?id=com.soulgenix.android&pcampaignid=web_share";
				else
					window.location.href =
						"https://apps.apple.com/app/wyb-the-play-social/id6596755967";
			}
		} catch (error) {
			console.error(
				"There has been a problem with your fetch operation:",
				error
			);
		}
	};

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const getData = async () => {
		try {
			const config = {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/x-www-form-urlencoded",
				},
			};

			try {
				const response = await fetch(
					"https://vb-api-lp.vyld.io/forms/checkDeviceType",
					config
				);
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const { data } = await response.json();
				setType(data?.deviceType);
				console.log("deviceType");
				if (data?.deviceType == "IOS") {
					// window.location.href =
					// 	"https://apps.apple.com/app/wyb-the-play-social/id6596755967";
					// setTimeout(() => {
					// 	window.location.href = "https://wyb.social/on-list";
					// }, 1000);
				} else if (data?.deviceType == "ANDROID") {
					// const btn = document.getElementById("clickBtn");
					// btn.click();
					// window.location.href =
					// 	"https://play.google.com/store/apps/details?id=com.soulgenix.android";
					// setTimeout(() => {
					// 	window.location.href = "https://wyb.social/on-list";
					// }, 1000);
				} else {
					// window.location.href = "https://wyb.social/pre-registration";
				}

				let a = data?.meta;
				a = JSON.parse(a);

				a.mobileModel = mobileModel;

				a = JSON.stringify(a);
				setMeta(a);
			} catch (error) {
				console.error("Error:", error);
			}
		} catch (err) {
			console.log("Error ", err);
		}
	};

	useEffect(() => {
		getData();

		if (window.grecaptcha) {
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute("6LfhKigqAAAAAG3IYlcIPW4TBFMYi2c21MzJAX9A", {
						action: "submit",
					})
					.then((token) => {
						setCheckNext(true);
					});
			});
		}
	}, []);

	const [phoneNumber, setPhoneNumber] = useState(Array(10).fill(""));
	const inputRefs = useRef([]);

	const handleFocus = (index) => {
		inputRefs.current[index].setSelectionRange(0, 1);
	};

	useEffect(() => {
		if (inputRefs.current[0]) {
			inputRefs.current[0].focus();
		}
	}, []);

	const buttonRef = useRef(null);

	useEffect(() => {
		if (buttonRef.current) {
			buttonRef.current.style.setProperty("background", "#C6FF00", "important");
		}
	}, []);

	const inputRef = useRef(null);
	const inputOtpRef = useRef(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [inputRef]);

	const [otpCheck, setOtpCheck] = useState(false);
	const [otp, setOtp] = useState("");

	useEffect(() => {
		let timer;
		if (otp.length < 4 && timeRemaining > 0) {
			// If OTP length is less than 4 and time is remaining, decrease the timeRemaining
			timer = setInterval(() => {
				setTimeRemaining((prevTime) => prevTime - 1);
			}, 1000);
		} else {
			clearInterval(timer);
		}
		return () => clearInterval(timer); // Cleanup interval on component unmount
	}, [otp, timeRemaining]);

	const handleChange = (e, index) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			// Only digits
			const newOtp = otp.split("");
			newOtp[index] = value;
			setOtp(newOtp.join(""));

			// Move focus to the next input
			if (value && index < 3) {
				inputRefs.current[index + 1].focus();
			}
		}
	};

	const handleKeyDown = (e, index) => {
		// Handle backspace key
		if (e.key === "Backspace" && index > 0 && !otp[index]) {
			inputRefs.current[index - 1].focus();
		}
	};

	const getOtp = async () => {
		console.log("clicked");
		if (number.length === 10) {
			console.log("Sending request For Getting OTP");
			if (type == "WEB" && selectedType == "") {
				alert("Select platform");
				return;
			}
			try {
				await axios.post("https://vb-api-lp.vyld.io/auth/otp", {
					phoneNumber: number.toString(),
				});
				setOtpCheck(true);
				setTimeRemaining(30);
				inputOtpRef.current.focus();
			} catch (err) {
				console.log("Error ", err);
			}
		} else {
			window.alert("Phone No Not Completed");
		}
	};

	return (
		<div
			style={{
				backgroundColor: "#5929E0",
				height: "100vh",
				position: "relative",
			}}
		>
			<div
				style={{
					backgroundImage: `url(${require("../../assets/images/bg.png")})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					opacity: 0.3,
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					zIndex: 1,
				}}
			></div>
			<Header />

			<div className="container_PRI">
				<GlobalStyle />

				<div className="subContainer_PRI">
					<img className="img_PRI" src={priIMG}></img>
					{otpCheck ? (
						<div className="input_Rect_PRI">
							<span className="input_react_head" style={{ width: "75%" }}>
								Enter the code sent to {number}
							</span>
							<div
								style={{
									marginTop: "3vh",
									display: "flex",
									justifyContent: "center",
									gap: "4vw",
								}}
							>
								{Array.from({ length: 4 }).map((_, index) => (
									<input
										key={index}
										type="Number"
										ref={(el) => (inputRefs.current[index] = el)}
										value={otp[index] || ""}
										maxLength={1}
										onChange={(e) => handleChange(e, index)}
										onKeyDown={(e) => handleKeyDown(e, index)}
										placeholder="0"
										className="numberInputaf_PRI"
										style={{ width: "10%", textAlign: "center" }}
									/>
								))}
							</div>
							<div className="fdbk_PRI">
								{otp.length < 4 ? (
									<>
										{timeRemaining != 0 && (
											<div className="progress-container">
												<div className="outer-circle">
													<div
														className="progress-ring"
														style={{
															background: `conic-gradient(black ${progressPercentage}%, rgba(22, 23, 22, 0.4) 0%)`,
														}}
													/>
													<div className="inner-circle"></div>
												</div>
											</div>
										)}
										<p
											className="resendOtp_PRI"
											onClick={timeRemaining === 0 ? getOtp : null}
											style={{
												opacity: timeRemaining === 0 ? "1" : ".6",
												cursor: timeRemaining === 0 ? "pointer" : "default",
											}}
										>
											Resend OTP{" "}
											{/* {timeRemaining !== 0 ? `in ${timeRemaining} sec` : ""} */}
										</p>
									</>
								) : (
									<></>
								)}
							</div>
							<div className="dirc_PRI">
								Great! Head to the{" "}
								{type == "ANDROID" || selectedType == "android"
									? "Play Store"
									: "App Store"}{" "}
								{type == "ANDROID" || selectedType == "android"
									? "and pre-register now "
									: `and tap on "Get"`}{" "}
								to be first in line!
							</div>
							{type === "ANDROID" ? (
								<img
									className="btn_store_PRI"
									src={BtnGP}
									onClick={handleButtonClick}
								/>
							) : (
								<img
									className="btn_store_PRI"
									src={BtnAP}
									onClick={handleButtonClick}
								/>
							)}
						</div>
					) : (
						<div className="input_Rect_PRI">
							<span className="input_react_head">Drop in your digits</span>
							<div className="inputContainer1_PRI">
								<input
									className="numberInput_PRI"
									value={"+91"}
									disabled={true}
									style={{ width: "18%" }}
								/>
								<input
									type="number"
									ref={inputRef}
									value={number}
									maxLength={10}
									onChange={(e) => {
										if (e.target.value.length === 11) return;
										else setNumber(e.target.value);
									}}
									placeholder="0000000000"
									className="numberInput_PRI"
									style={{ width: "80%", zIndex: "5" }}
								/>
							</div>
							{type == "WEB" && !otpCheck && (
								<RadioContainer style={{ marginTop: "3vh" }}>
									<RadioOption>
										<input
											type="radio"
											id="android"
											name="type"
											value="android"
											checked={selectedType === "android"}
											onChange={(e) => setSelectType(e.target.value)}
											style={{ marginBottom: "12px" }}
										/>
										<label htmlFor="android" style={{ height: "50px" }}>
											<i className="fab fa-google-play"></i> Google Play
										</label>
									</RadioOption>
									<RadioOption>
										<input
											type="radio"
											id="ios"
											name="type"
											value="ios"
											checked={selectedType === "ios"}
											onChange={(e) => setSelectType(e.target.value)}
											style={{ marginBottom: "12px" }}
										/>
										<label htmlFor="ios" style={{ height: "50px" }}>
											<i className="fab fa-apple"></i> Apple Store
										</label>
									</RadioOption>
								</RadioContainer>
							)}
							<button className="submitButton_PRI" onClick={getOtp}>
								<span>Verify With OTP</span>
							</button>
						</div>
					)}

					{/* text */}
					{!otpCheck ? (
						selectedType == "android" || type == "ANDROID" ? (
							<div
								style={{
									marginTop: "2vh",
									display: "flex",
									justifyContent: "center",
									color: "#FFFFFFB2",
									width: "95vw",
									textAlign: "center",
									zIndex: "2",
								}}
							>
								After entering the OTP, tap on "Pre-Register" on the play store
							</div>
						) : selectedType == "ios" || type == "IOS" ? (
							<div
								style={{
									marginTop: "2vh",
									display: "flex",
									justifyContent: "center",
									color: "#FFFFFFB2",
									width: "95vw",
									textAlign: "center",
								}}
							>
								After entering the OTP,
								<br /> tap on "Get" on the app store
							</div>
						) : (
							<></>
						)
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
}

const RadioContainer = styled.div`
	display: flex;
	margin-bottom: 3vh;
	align-items: center;
	font-family: "Montserrat", sans-serif;
	z-index: 2;
`;

const RadioOption = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	padding: 6px;
	margin-left: 2vw;
	font-family: "Montserrat", sans-serif;
	margin: 0px 0px 0px 5px;
	z-index: 2;

	label {
		margin-left: 0.5vw;
		display: flex;
		align-items: center;

		i {
			font-size: 15px;
			margin-right: 0.2vw;
		}
	}

	&:first-child {
		margin-left: 0;
	}
`;
const SubmitCon = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
	z-index: 2;
`;
const SubmitButton = styled.button`
  padding: 8px;
  background: #C6FF00;
  border-radius: 6px;
  font-weight: bold;
  font-size:16px;
  cursor: pointer;
  height:50px;
  width:"100%"
  font-family: 'Montserrat', sans-serif;
  z-index: 2;

  i {
    margin-right: 2vw;
  }
   width: 220px;
  @media (max-width: 768px) {
    width: 220px;
  }
`;
